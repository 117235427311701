import { useStaticQuery, graphql } from "gatsby";

export const useSubmitIntermediary = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Proposal Information" }) {
        content
      }
    }
  `);
  return data.wpPage.content;
};
