import React, { useState } from "react";
import PropTypes from "prop-types";
import IsrLink from "../../../IsrLink/IsrLink";
import IsrButton from "../../../IsrButton/IsrButton";
import "./StepItem.scss";

const StepItem = ({ step }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleStep = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`step-item ${isOpen ? "step-open" : ""}`}>
      {/* eslint-disable-next-line */}
      <div className="step-header" onClick={toggleStep}>
        {step.heading && <p className="step-count">{step.heading}</p>}
        {step.subheading && <p className="step-title">{step.subheading}</p>}
        <svg
          width="9"
          height="7"
          xmlns="http://www.w3.org/2000/svg"
          className={`${isOpen ? "svg-open" : ""}`}
        >
          <path
            d="m5.253 5.926 3.296-3.767A1 1 0 0 0 7.796.5H1.204A1 1 0 0 0 .45 2.159l3.296 3.767a1 1 0 0 0 1.506 0z"
            fill="#000"
            fillRule="evenodd"
            opacity=".3"
          />
        </svg>
      </div>
      {isOpen ? (
        <div className="step-body">
          {step.copy && (
            <p
              className="step-description"
              dangerouslySetInnerHTML={{ __html: step.copy }} // eslint-disable-line react/no-danger
            />
          )}
          {step.link && step.link.title && (
            <IsrLink link={step.link}>
              <IsrButton size="md" variant="primary">
                {step.link.title}
              </IsrButton>
            </IsrLink>
          )}
        </div>
      ) : null}
    </div>
  );
};

StepItem.propTypes = {
  step: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    copy: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
    }),
  }).isRequired,
};

export default StepItem;
