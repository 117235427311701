import { useStaticQuery, graphql } from "gatsby";

const useSubmitIntermediarySubmitAProposal = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Proposal Information" }) {
        submitProposalIntermediary {
          submitAProposalSectionPage {
            heading
            tabContent {
              tabItem {
                title
                tabContent {
                  copy
                  heading
                  subheading
                  link {
                    target
                    title
                    url
                  }
                  showStep
                }
              }
            }
            showSection
          }
        }
      }
    }
  `);
  
  // console.log(data);

  return data.wpPage.submitProposalIntermediary.submitAProposalSectionPage;
};

export default useSubmitIntermediarySubmitAProposal;
