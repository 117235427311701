import React from "react";
import PropTypes from "prop-types";
import StepItem from "../StepItem/StepItem";

const StepItemsList = ({ stepItems }) => {
  return (
    <div className="row">
      {stepItems &&
        stepItems.map((stepItem) => {
          if (stepItem.showStep)
            return (
              <div
                className="col-lg-6"
                key={`${stepItem.heading}-${stepItem.subheading}`}
              >
                <StepItem step={stepItem} />
              </div>
            );
          return null;
        })}
    </div>
  );
};

StepItemsList.propTypes = {
  stepItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StepItemsList;
