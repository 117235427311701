import React, { useState } from "react";
import useSubmitIntermediarySubmitAProposal from "../../../../hooks/useSubmitIntermediary/useSubmitIntermediarySubmitAProposal";
import Spacer from "../../../Spacer/Spacer";
import IsrTabs from "../../../IsrTabs/IsrTabs";
import StepItemsList from "../StepItemsList/StepItemsList";

const SubmitProposalSection = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { heading, tabContent, showSection } =
  useSubmitIntermediarySubmitAProposal();

  //console.log("proposal section" + heading + tabContent);

  if (showSection)
    return (
      <div className="container">
        {heading && <h2>{heading}</h2>}
        <Spacer size={30} mobileSize={20} />
        {tabContent && (
          <IsrTabs
            tabs={tabContent.map((tabItem, i) => ({
              id: i,
              name: tabItem.tabItem.title,
            }))}
            onTabSelect={setSelectedTab}
          />
        )}
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            {tabContent[selectedTab].tabItem.tabContent && (
              <StepItemsList
                stepItems={tabContent[selectedTab].tabItem.tabContent}
              />
            )}
          </div>
          <div className="col-md-1" />
        </div>
        <Spacer size={70} mobileSize={30} />
      </div>
    );
  return null;
};

export default SubmitProposalSection;
