import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import SubmitProposalsIntermediary from "../components/SubmitProposalsIntermediary/SubmitproposalsIntermediary";
import SubmitProposalsIntermediarySubmitProposalSection from "../components/SubmitProposalsIntermediary/components/SubmitProposalSection/SubmitProposalSection";

import Footer from "../components/Footer/Footer";

const SubmitProposalIntermediaryPage = () => {
  const submitproposalstyles = `
  <style>
  .submit-intermediary ul{
    margin: 10px 0;
  }

  .submit-intermediary ul li{
    text-align:left;
  }


  .has-text-align-left{
    text-align:left;
  }

  .has-text-align-center{
    text-align:center;
  }
  .has-text-align-right{
    text-align:right;
  }


  .submit-intermediary h1 {
     color:black;
     font-size: 7rem;
     line-height: 7rem;
     margin: 10px 0;
    }

    .submit-intermediary p{
      text-align:left;
    }

   .submit-intermediary p.aligncenter{
     text-align:center;
   }

   .submit-intermediary p.alignleft{
    text-align:left;
  }
  .submit-intermediary p.alignright{
    text-align:right;
  }


    .submit-intermediary h2 {
      color:black;
      font-size: 5rem;
      line-height: 5rem;
      margin: 10px 0;
     }

     .submit-intermediary h3 {
      color:black;
      font-size: 3.6rem;
      line-height: 3.6rem;
      margin: 10px 0;
     }

     .submit-intermediary h4 {
      color:black;
      font-size: 3rem;
      line-height: 3rem;
      margin: 10px 0;
     }

     p.fullwidth {
      margin:0 !important;
     }
     p.aligncenter {
      text-align:center;
     }

  </style>

  `;
  return (
    <Layout>
      <Header />
      <div
          dangerouslySetInnerHTML={{
            __html: submitproposalstyles,
          }}
        />
      <SubmitProposalsIntermediary />
      <div className="row"><div className="col-md-1"></div><div className="col-md-11">
      <SubmitProposalsIntermediarySubmitProposalSection />
      </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default SubmitProposalIntermediaryPage;
