import React from "react";
import { useSubmitIntermediary } from "../../hooks/useSubmitIntermediary/useSubmitIntermediary";
import Spacer from "../Spacer/Spacer";
import "./SubmitProposalsIntermediary.scss";

import SubmitProposalSection from "./components/SubmitProposalSection/SubmitProposalSection";

const SubmitProposalsIntermediary = () => {
  const content = useSubmitIntermediary();
  return (
    <div className="container submit-intermediary">
      <Spacer size={100} mobileSize={50} />
      <div className="row">
        <div className="col-md-2" />
        <div
          className="col-md-8 content-wrapper"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <div className="col-md-2" />
      </div>
    </div>
  );
};

export default SubmitProposalsIntermediary;
